// =============================================================================
// Header
// =============================================================================

.global-header {
	display: flex;
	flex: 1 0 auto;
	// justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: $container;
	margin: 0 auto;
	padding: 3rem 1.3rem 2rem 1.3rem;
	z-index: 9;
	@include mq(medium) {
		padding: 3rem 1.3rem;
	}
	&--logo {
		width: 130px;
		z-index: 1;
		@include mq(medium) {
			width: 160px;
		}
		a {
			display: block;
		}
	}
	&--nav {
		display: none;
		@include mq(medium) {
			display: flex;
			align-items: center;
			height: 100%;
			margin-left: auto;
			margin-right: 1rem;
		}
		ul {
			padding-left: 0;
			display: flex;
			li {
				display: inline;
				line-height: 100%;
				a {
					position: relative;
					font-family: $font-family-display;
					color: $color-text-base;
					margin: 0 1rem;
					font-weight: $font-weight-normal;
					text-decoration: none;
					font-size: fluid-type($button-size);
					@include mq(medium) {
						margin: 0 1rem;
					}
					@include mq(large) {
						margin: 0 1.5rem;
					}
					&:hover {
						color: $color-text-base;
						text-decoration: none;
						&:after {
							content: '';
							position: absolute;
							width: calc(100% + 10px);
							height: 3px;
							left: -5px;
							bottom: -15px;
							background-color: $carveti-blue;
						}
					}
				}
			}
			.current-menu-item a,
			.current_page_item a {
				position: relative;
				&:after {
					content: '';
					position: absolute;
					width: calc(100% + 10px);
					height: 3px;
					left: -5px;
					bottom: -15px;
					background-color: $green;
				}
			}
			.current_page_item a {
				&:after {
					content: '';
					height: 3px;
					bottom: -10px;
				}
			}
		}

		&-btn {
			cursor: pointer;
			overflow: visible;
			background: none;
			border: 0;
			outline: none;
			display: block;
			margin-top: 7px;
			z-index: 4;
			margin-left: auto;
			@include mq(medium) {
				display: none;
			}
		}

		&__mobile {
			position: fixed;
			padding: 50px;
			padding-top: 100px;
			right: -100%;
			top: 0;
			width: 100%;
			height: 100vh;
			z-index: 3;
			background: transparent;
			transition: all 0.2s ease-in-out;
			display: block;
			@include mq(medium) {
				display: none;
			}
			ul {
				display: flex;
				flex-direction: column;
				li {
					line-height: 2.2rem;
					text-align: center;
					a {
						color: white;
						padding: 0;
						font-size: 1.5rem;
						&:hover {
							color: white;
						}
					}
				}
			}
		}
	}
}

ul.menu {
	padding-left: 0;
	li {
		padding-left: 0;
		display: inline-block;
	}
}

.global-header--nav__mobile.is-active {
	transform: translateX(-100%);
	transition: all 0.2s ease-in-out;
	background: $carveti-blue;
}
/*
* SVG animation
*/
.global-header--nav-btn.is-active .global-header--nav-inner,
.global-header--nav-btn.is-active .global-header--nav-inner::before,
.global-header--nav-btn.is-active .global-header--nav-inner::after {
	background-color: white;
}
.global-header--nav-btn.is-active {
	position: fixed;
	right: 1.3rem;
	margin-top: 4px;
}

.global-header--nav-box {
	width: 40px;
	height: 26px;
	display: inline-block;
	position: relative;
}

.global-header--nav-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
	span {
		visibility: hidden;
		position: absolute;
	}
}
.global-header--nav-inner,
.global-header--nav-inner::before,
.global-header--nav-inner::after {
	width: 40px;
	height: 3px;
	background-color: $dark-blue;
	border-radius: 20px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
}
.global-header--nav-inner::before,
.global-header--nav-inner::after {
	content: "";
	display: block;
}
.global-header--nav-inner::before {
	top: -10px;
}
.global-header--nav-inner::after {
	bottom: -10px;
}

.global-header--nav-inner {
	transition-duration: 0.2s;
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.global-header--nav-inner::before,
.global-header--nav-inner::after {
	transition-duration: 0s;
	transition-delay: 0.1s;
	transition-timing-function: linear;
}
.global-header--nav-inner::before {
	transition-property: top, opacity;
}
.global-header--nav-inner::after {
	transition-property: bottom, transform;
}

.is-active .global-header--nav-inner {
	transform: rotate(765deg);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.is-active .global-header--nav-inner::before, .is-active .global-header--nav-inner::after {
	transition-delay: 0s;
}
.is-active .global-header--nav-inner::before {
	top: 0;
	opacity: 0;
}
.is-active .global-header--nav-inner::after {
	bottom: 0;
	transform: rotate(90deg);
}
