.text-image {
	display: flex;
	position: relative;
	flex-direction: column;
	@include mq(medium) {
		flex-direction: row;
	}
	&--text {
		width: 100%;
		padding: 1.5rem 0;
		@include mq(medium) {
			width: 50%;
		}
		@include mq(large) {
			padding: 3rem 1.5rem 0 1.5rem;
		}
		@include mq(xlarge) {
			padding: $spacing-med $spacing-med 0 $spacing-med;
		}
	}
	&--image {
		width: 100%;
		margin-left: 0;
		z-index: 2;
		padding-bottom: $spacing-med;
		@include mq(medium) {
			width: 50%;
			margin-left: 0;
			left: 0;
			padding-bottom: $spacing-med;
		}

		&__bottom {
			position: absolute;
			width: 50%;
			margin: 0 auto;
			z-index: 9;
			transform: translate(-50px, 20px);
			@include mq(medium) {
				position: relative;
				width: 80%;
				transform: translateY(50px);
			}
			@include mq(large) {
				width: 80%;
				transform: translateY(30px);
			}
			img {
				transform: translateY(50px);
				@include mq(medium) {
					transform: translateY(50px);
				}
				@include mq(large) {
					transform: translateY(30px);
				}
			}
		}
	}
}