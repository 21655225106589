// =============================================================================
// Buttons
// =============================================================================

.btn,
.wpcf7-submit,
.input.wpcf7-submit {
	display: inline-block;
	border-radius: $radius-large;
	padding: 0.5rem 1.75rem;
	color: $medium-blue;
	background-color: $green;
	// line-height: 40px;
	// box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
	font-family: $font-family-display;
	font-weight: $font-weight-normal;
	text-decoration: none !important;
	font-size: fluid-type($button-size);
	text-align: center;
	cursor: pointer;
	top: 0;
	// transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out;
	// @include mq($until: medium) {
	// 	font-size: 1.8rem;
	// }
	

	&:hover, &:focus {
		background-color: $yellow;
		outline: 0;
		color: $medium-blue;
		text-decoration: none;
		position: relative;
		// top: -3px;
		// transition: background-color .2s ease-in-out, box-shadow .2s ease-in-out;
	}

	&-icon {
		margin-right: 0.5rem;
		&--play {
			width: 12px;
			height: 12px;
			transform: translateY(-2px);
			fill: $medium-blue;
		}
	}

	&--small {
		padding: 0.25rem 1.5rem;
		font-size: $button-size;
	}
}

.btn--secondary {
	background-color: white;

	&:hover, &:focus {
		background-color: $green;
	}
}
