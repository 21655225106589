.vacancies {
	position: relative;
	// padding: $spacing-small / 2 0 $spacing-small;
	padding: $spacing-small 0;
	@include mq(medium) {
		// padding: $spacing 0;
		padding: $spacing-small * 1.5 0;
	}

	&-list {
		width: 100%;
		margin: 0 auto;
		@include mq(medium) {
			width: 80%;
		}
		@include mq(large) {
			width: 75%;
		}
		@include mq(xlarge) {
			width: 50%;
		}
		p:last-child {
			margin-bottom: 0;
		}
		&--title {
			margin-bottom: $spacing-small / 2;
		}

		&--item {
			// margin-bottom: 1.3rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			padding: 1.3rem;
			border: 1px solid $dark-blue;
			@include mq(medium) {
				flex-direction: row;
				padding: 2.2rem;
			}

			.btn {
				margin-top: 1.3rem;
				@include mq(medium) {
					margin-top: 0;
				}
			}

			&__content {
				&-title {
					font-family: $font-family-display;
				}
				p.lead {
					margin-bottom: 1rem!important;
				}
				.post-category {
					margin-left: 0;
					background-color: $light-blue;
					display: inline-block;
					p {
						background-color: transparent;
					}
					@include mq(medium) {
						margin-left: 1rem;
					}
					
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
	}
}

.vacancies-list--item + .vacancies-list--item {
	margin: 1.3rem 0 0;
}