.text-details {
	display: flex;
	flex-direction: column;
	padding: $spacing-small / 2 0;
	@include mq(medium) {
		flex-direction: row;
		padding: $spacing 0;
	}

	&--detail {
		width: 100%;
		margin-left: 0;
		padding: 2rem;
		@include mq(medium) {
			width: 40%;
			margin-left: 10%;
			padding: 3rem;
		}
	}
}