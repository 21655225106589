// =============================================================================
// Figure
// =============================================================================

figure {
	margin: 0;
}

figcaption {
	margin-top: 0;
}

figcaption p {
	font-size: fluid-type($font-size-small);
}
