// =============================================================================
// Forms
// =============================================================================

form {
	p:last-of-type {
		margin-bottom: 0;
	}
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	padding: 0.75em 1rem;
	background-color: #fff;
	border: 1px solid #CCCCCC;
	border-radius: 5px;
	box-sizing: border-box;
	font-family: $font-family-body;
	font-size: 21px;
	transition: box-shadow .2s ease-in-out;
	width: 100%;
	@include mq($until: medium) {
		font-size: $body-size;
	}
	@include mq(medium) {
		padding: 1rem 1.5rem;
	}
}

select {
	background: #fff;
	border: 1px solid #F0F3FB;
	border-radius: 4px;
	width: 100%;
	padding: 20px;
	font-size: 16px;
	color: #3F3F3F;

	/* Here's the code we need */
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	 -o-appearance: none;
		appearance: none;
}

.select-wrapper {
	position: relative;	
}

.select-wrapper:after {
  	content: url("../src/icons/select-icon.svg");
	position: absolute;
	width: 14px;
	height: 22px;
	top: 10px;
	// transform: translateY(-50%);
	right: 2rem;
	pointer-events: none;
}


select::-ms-expand {
  display: none;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
}

textarea {
	min-height: 65px;
	padding-top: 6px;
	padding-bottom: 6px;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
	border: 1px solid $color-grey-dark;
	outline: 0;
}

label {
	display: block;
	margin: .5em 0;
	font-family: $font-family-display;
}
.wpcf7-form-control-wrap {
	input, textarea {
		margin: 1rem 0;
	}
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	// display: block;
	padding: 0.75rem 1.5rem;
	// margin: .5em 0;
	// background: $color-primary;
	// color: #fff;
	// border-radius: $radius;
	// transition: (all 0.2s linear);
	border: 0;
	appearance: none;
	-webkit-appearance: none;
}

input[type="submit"].center,
input[type="reset"].center,
input[type="button"].center {
	margin: 0 auto;
}

// input[type="submit"]:hover,
// input[type="reset"]:hover,
// input[type="button"]:hover,
// input[type="submit"]:focus,
// input[type="reset"]:focus,
// input[type="button"]:focus {
// 	background: $color-grey;
// 	text-decoration: none;
// 	color: #fff;
// }

.required {
	color: #BF5D58;
}

.form-acceptance {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	padding: 2rem;
	font-size: 18px;
	margin-bottom: 2.6rem;
	border-radius: 10px;
	
	.wpcf7-acceptance {
		margin-right: 1.3rem;
	}

	p {
		font-size: 18px;
	}

	> * + * {
		margin: 1rem 0 0;
	}

}

.form-acceptance__field {
	display: flex;
	padding: 1rem;
	background: white;

	border-radius: 5px;
}


 /* custom checkbox styling for contact form 7 checkbox */
span.wpcf7-list-item {
	display: inline-block;
	margin: 0;
}
.wpcf7 .wpcf7-list-item {
	display: block;
	margin-bottom: 10px;
}
.wpcf7-checkbox label {
	position: relative;
	cursor: pointer;
	font-family: $font-family-body;
}
.wpcf7-checkbox input[type=checkbox] {
	/*position: relative;*/
	position: absolute;
	visibility: hidden;
	width: 30px;
	height: 30px;
	top: 0;
	left: 0;
}
.wpcf7-checkbox input[type=checkbox] + span {
/* border: 3px solid red; */
}
.wpcf7-checkbox input[type=checkbox] + span:before {
	display: block;
	position: absolute;
	content: '';
	border-radius: 0;
	height: 30px;
	width: 30px;
	top: 0px;
	left: 0px;
	background: white;
	border: 2px solid #CCCCCC;
	border-radius: 5px;
}
.wpcf7-checkbox input[type=checkbox] + span:after {
	display: block;
	position: absolute;
	content: "\2713";
	height: 30px;
	width: 30px;
	top: 0;
	left: 0;
	visibility: hidden;
	font-size: 18px;
	text-align: center;
	line-height: 30px;
	background: $yellow;
	// border: 2px solid #CCCCCC;
	border-radius: 5px;
}
.wpcf7-checkbox input[type=checkbox]:checked + span:before {
	background: transparent;
}
.wpcf7-checkbox input[type=checkbox]:checked + span:after {
	visibility: visible;
}
.wpcf7-list-item-label {
	margin-left: 60px;
	display: inline-block;
}