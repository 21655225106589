#cookies-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10000;

  width: 100%;
  padding: 1rem;

  background-color: $color-tertiary;

  text-align: center;
}