.team {
    padding: $spacing-small / 1.5 0;
	@include mq(medium) {
		padding: $spacing 0;
	}
	margin-top: 0 !important;
	&-list {
		position: relative;
		z-index: 1;
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		flex-direction: column;
		justify-content: center;
		@include mq(small) {
			flex-direction: row;
			margin: 0 -1.3rem;
		}
	
		&--item {
			position: relative;
			display: flex;
			margin: 1.3rem 0 0 0;
			flex-direction: column;
			// height: calc(100vw - 2.6rem);
			width: 100%;
			outline: 0;
			background-color: rgba(white,0.5);
			&:nth-child(-n+1) {
				margin-top: 0;
			}
			@include mq(small) {
				margin: 2.6rem 1.3rem 0 1.3rem;
				padding: 0;
				overflow: hidden;
				height: calc(100vw / 2 - 2.6rem);
				width: calc(100% / 2 - 2.6rem);
				&:nth-child(-n+2) {
					margin-top: 0;
				}
			}
			@include mq(xlarge) {
				width: calc(100% / 3 - 2.6rem);
				height: calc(100vw / 3 - 2.6rem);
				max-height: calc(1400px / 3 - 2.6rem);
				&:nth-child(-n+3) {
					margin-top: 0;
				}
			}

			// &:hover {
			// 	.team-list--item__bio {
			// 		@include mq(medium) {
			// 			height: 100%;
			// 		}
			// 	}
			// 	.team-list--item__bio-desc {
			// 		display: block;
			// 		visibility: visible;
			// 		opacity: 1;
			// 	}
			// }

			&:hover {
				.team-list--item__image img {
					transform: scale(1.1);
				}
 			}

			&__image {
				min-width: 100%;
				min-height: 100%;
				height: calc(100vw - 2.6rem);
				overflow: hidden;

				img {
					display: block;
					width: 100%;
					height: 100%;

					object-fit: cover;
					object-position: center;

					transition: 0.25s all;
				}
			}

			&__bio {
				position: absolute;
				padding: 1.3rem;
				width: 100%;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 1.3rem;

				&::after {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					bottom: 0;
					left: 0;
					background: $dark-blue;
					opacity: 0.6;

				}

				p {
					margin: 0;
				}

				&-name {
					position: relative;
					z-index: 1;
					font-family: $font-family-display;
					p {
						font-size: 24px;
					}
				}

				&-position {
					position: relative;
					z-index: 1;
					p {
						font-size: 18px;
					}
				}

				&-desc {
					position: relative;
					z-index: 1;
					display: flex;
					visibility: visible;
					opacity: 1;
					@include mq(medium) {
						display: none;
						visibility: hidden;
						opacity: 0;
					}
					p {
						font-size: 21px;
						margin-top: 1rem;
					}
				}
			}
		}
	}
}

.team + .cta-container {
	margin-top: 0;
}