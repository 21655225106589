.image-grid {
    display: flex;
	margin: 0;
	flex-direction: column;
	@include mq(medium) {
		flex-direction: row;
		margin: 0 -1.3rem 2.6rem -1.3rem;
    }
    
    &--item {
		margin-bottom: 1rem;
		@include mq(medium) {
			margin-bottom: 0;
			padding: 0 1.3rem;
        }
    }
}