.service-list {
	padding: $spacing-small 0;
	margin-top: 0 !important;
	@include mq(medium) {
		padding: $spacing-small * 1.5 0;
	}
	.tick {
		display: flex;
		flex-wrap: wrap;
		li {
			width: 50%;
			display: inline-block;
			padding-right: 3.5rem;
		}
	}
}