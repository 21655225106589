.pagination {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
	@include mq(medium) {
		margin-top: $spacing-small;
	}
	&__list {
		display: flex;
		padding-left: 0;
		list-style-type: none;
		li {
			margin: 0 0.5rem;
			font-family: $font-family-display;
		}
		a {
			font-weight: $font-weight-bold;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		.current-pg {
			font-weight: $font-weight-bold;
			text-decoration: underline;
		}
	}
	.prev-pg, .next-pg {
		background: $green;
		width: 40px;
		height: 40px;
		border-radius: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		border: none;
		&:hover {
			background-color: $yellow;
			outline: 0;
			color: $medium-blue;
			box-shadow: 0 5px 10px rgba(0,0,0,.2);
		}
	}
	.prev-pg {
		margin-right: 2rem;
		svg {
			transform: rotate(180deg);
		}
	}
	.next-pg {
		margin-left: 2rem;
	}
}

.pagination-pg {
	margin: 0 0.5rem;

	font-family: $font-family-display;
	font-size: 21px;
	font-weight: $font-weight-bold;
	text-decoration: none;

	border: none;
	background: none;

			&:hover {
				text-decoration: underline;
			}

			&.mixitup-control-active {
				text-decoration: underline;
			}

}