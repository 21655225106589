.video {
	display: flex;
	position: relative;
	flex: 1 0 auto;
	overflow: hidden;
	align-items: flex-start;
	flex-direction: column;
	background-size: cover;
	background-position: center center;
	@include mq(large) {
		flex-direction: row;
	}

	&-shape {
		display: none;
		position: absolute;
		width: 50%;
		// height: 100%;
		top: 0;
		left: -100px;
		transform: translateX(-100px) scale(1.4);
		@include mq(large) {
			display: block;
			transform: translateX(-150px) scale(1.1);
		}
	}
	&-content {
		// position: absolute;
		// left: 0;
		background: transparent;
		width: 100%;
		margin-left: auto;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		padding: $spacing-small 0;
		z-index: 2;
		position: relative;
		h1, h2, h3, h4, h5, h6, p {
			color: white;
		}
		// @include mq($until: large) {
		// 	h1, h2, h3, h4, h5, h6, p {
		// 		color: white;
		// 	}
		// }
		@include mq(medium) {
			padding: $spacing 0 $spacing;
		}
		@include mq(large) {
			width: 50%;
			
		}
		@include mq(xlarge) {
			width: 50%;
			// padding: $spacing-med;
		}
	}
	&-image {
		// width: 100vw;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		
		@include mq(large) {
			width: 70%;
			margin-left: auto;
			margin-top: auto;
			margin-right: -100px;
			left: 0;
			transform: translateX(-100px);
		}
		img {
			width: 100%;
		}
	}
	&-background {
		position: absolute;
		height: 100%;
		width: 100%;
		overflow: hidden;
		z-index: 0;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-width: none;
			@include mq($until: medium) {
				height: 100%;
			}
			@include mq(medium) {
				min-width: 100%;
				min-height: 100%;
			}
		}
	}
}