.portfolio {
	position: relative;
	padding: $spacing-small / 2 0 $spacing-small;
	@include mq(medium) {
		padding: $spacing 0;
	}

	&-list {
		width: 100%;
		margin: 0 auto;
		@include mq(medium) {
			width: 80%;
		}
		@include mq(large) {
			width: 75%;
		}
		@include mq(xlarge) {
			width: 50%;
		}
		p:last-child {
			margin-bottom: 0;
		}
		&--title {
			margin-bottom: $spacing-small / 2;
		}

		&--item {
			margin-bottom: 1.3rem;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			padding: 1.3rem;
			border: 1px solid $dark-blue;
			@include mq(medium) {
				flex-direction: row;
				padding: 2.2rem;
			}

			.btn {
				margin-top: 1.3rem;
				order: 3;
				@include mq(medium) {
					order: 0;
					margin-top: 0;
				}
			}

			&__content {
				&-title {
					font-family: $font-family-display;
				}
				p {
					margin-bottom: 1rem !important;
				}
			}

			&__categories {
				width: 100%;
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				margin: -0.5rem;

				.post-category {
					background-color: $light-blue;
					p {
						background-color: transparent;
					}
					
				}
			}
		}
	}
}