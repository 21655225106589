// =============================================================================
// Alert
// =============================================================================

.global-alert {
    background: $color-primary;
    text-align: center;
    padding: 1rem;
}

.global-alert p {
    color: white;
    margin: 0;
}