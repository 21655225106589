.projects-slider {
	display: flex;
	padding: $spacing-small 0;
	margin: 0;
	flex-direction: column;
	@include mq(large) {
		// padding: $spacing-small 0;
		flex-direction: row;
		margin: 0 -1.3rem;
		align-items: center;
	}
	&--content {
		width: 100%;
		padding: 0;
		@include mq(large) {
			width: 35%;
			padding: 0 3rem 0 1.3rem;
		}
		@include mq(xlarge) {
			width: 40%;
			padding: 0 3rem 0 1.3rem;
		}
	}
	&--images {
		width: 100vw;
		left: 50%;
		transform: translateX(-50%);
		padding: 0;
		margin: $spacing-small / 2 0 0 0;
		position: relative !important;
		right: 0;
		@include mq(large) {
			width: 65%;
			left: auto;
			transform: translateX(0);
			padding: 0 0 0 1rem;
			margin: 0;
			// position: absolute !important;	
		}
		@include mq(xlarge) {
			width: 55%;
		}

		img {
			width: 100%;
		}
		.slick-list {
				position: relative;
				display: block;
				overflow: hidden;

				margin: 0;
				@include mq(large) {
					padding:0 20% 0 0 !important;
				}
			}
	}
	&--title {
		background: $medium-blue;
		font-family: $font-family-display;
		color: white;
		position: absolute;
		bottom: 20px;
		padding: 1rem 2rem 1rem 1rem;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		left: 0;
		p {
			margin-bottom: 0;
		}
	}
}

