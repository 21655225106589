// =============================================================================
// Helper classes
// =============================================================================

.u-bold { font-weight: bold!important; }
.u-semibold { font-weight: 600!important; }
.u-regular { font-weight: normal!important; }
.u-light { font-weight: 300!important; }
.u-italic { font-style: italic!important; }
.u-caps { text-transform: uppercase!important; }
.u-left-align { text-align: left!important; }
.u-center { text-align: center!important; }
.u-right-align { text-align: right!important; }

// WordPress specifc

img.aligncenter     { display: block; margin-left: auto; margin-right: auto; }
.alignright         { float: right; }
.alignleft          { float: left; }
.aligncenter        { display: block; margin-left: auto; margin-right: auto; }


img.alignleft,
img.alignright {
	width: 100%;
	margin: 2rem 0;
}

img.alignright { 
	
	@include mq(small) {
		width: 25%;
		float: right;
		margin: 0 0 1rem 1rem;
	}

}

img.alignleft { 
	
	@include mq(small) {
		width: 25%;
		float: left;
		margin: 0 1rem 1rem 0;
	}

}

// Show and hide
.hidemobile {
	@include mq($until: medium) {
		display: none !important;
	}
}

.hidedesktop {
    @include mq(medium) {
		display: none !important;
	}
}

.pt {
	padding-top: $spacing-small;
	@include mq(medium) {
		padding-top: $spacing-small * 1.5;
	}
}
.pb {
	padding-bottom: $spacing-small;
	@include mq(medium) {
		padding-bottom: $spacing-small * 1.5;
	}
}


// debugging
@if $debug == true {
    * {
        box-shadow: inset 0 0 0 1px red;
    }
}
