.shape {
	position: absolute;
	z-index: 0;

	&-yellow {
		fill: $yellow;
	}
	&-mediblue {
		fill: $medium-blue;
	}
	&-white {
		fill: white;
	}
	&-green {
		fill: $green;
	}
	&-grey {
		fill: $light-grey;
	}
}

.hero-home {
	.shape {	
		&-1 {
			left: 0;
			top: 60px;
			width: 43px;
			height: 135px;
			@include mq(medium) {
				width: 70px;
				height: 220px;
			}
		}
		&-2 {
			width: 26px;
			height: 51px;
			@include mq($until: medium) {
				left: 40px;
				top: 170px;
				transform: rotate(-90deg);
			}
			@include mq(medium) {
				right: 30%;
				bottom: 0px;
				transform: translateY(50%);
				width: 85px;
				height: 170px;
			}
		}
		&-3 {
			display: none;
			@include mq(large) {
				right: 0;
				top: 60%;
				transform: translateX(-50%) rotate(90deg);
				display: block;
				width: 85px;
				height: 170px;
			}
		}
	}
}
.hero-page,
.hero-blog {
	.shape {	
		&-1 {
			left: 0;
			top: 60px;
			width: 43px;
			height: 135px;
			@include mq(medium) {
				left: 30%;
				top: 0px;
				transform: rotate(-90deg);
				width: 68px;
				height: 137px;
			}
		}
		&-2 {
			right: 0;
			bottom: 20px;
			width: 45px;
			height: 45px;
			@include mq(medium) {
				left: -20px;
				bottom: 60px;
				width: 75px;
				height: 75px;
			}
			@include mq(xlarge) {
				left: 5%;
			}
		}
	}
}
.hero-blog {
	.shape {	
		&-1 {
			@include mq($until: medium) {
				display: none;
			}
		}
	}
}
.hero-portfolio {
	.shape {	
		&-1 {
			display: none;
			left: 0;
			bottom: 0;
			width: 400px;
			height: 400px;
			transform: translate(-10px, 10px);
			@include mq(medium) {
				display: block;
				position: absolute;
				z-index: 0;
			}
		}
		&-2 {
			width: 45px;
			height: 45px;
			bottom: 20px;
			transform: translateX(-10px);
			@include mq(medium) {
				left: 60px;
				top: 60%;
				width: 113px;
				height: 113px;
			}
		}
		&-3 {
			right: 20px;
			bottom: 0;
			transform: translate(50%, -50%) rotate(180deg);
			display: block;
			width: 50px;
			height: 100px;
			@include mq(medium) {
				right: 40px;
				width: 85px;
				height: 170px;
			}
		}
	}
}

.posts-featured,
.vacancies,
.portfolio  {
	.shape {	
		&-1 {
			left: 0;
			top: $spacing;
			width: 43px;
			height: 135px;
			display: none;
			@include mq(medium) {
				display: block;
				top: $spacing + 80px;
				width: 70px;
				height: 220px;
			}
		}
		&-2 {
			width: 45px;
			height: 45px;
			right: 0px;
			bottom: 20px;
			@include mq(medium) {
				right: 0px;
				bottom: 60px;
				width: 75px;
				height: 75px;
			}
		}
	}
}
.cta {
	.shape {	
		&-1 {
			right: 0;
			top: 0px;
			width: 70px;
			height: 220px;
			transform: translate(60%, -30px) rotate(180deg);
			z-index: -1;
			@include mq(medium) {
				transform: translate(50%, -30px) rotate(180deg);
			}
		}
		&-2 {
			width: 45px;
			height: 45px;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			@include mq(medium) {
				right: 2%;
				width: 300px;
				height: 100%;
				min-height: 660px;
			}
		}
		&-3 {
			display: none;
			@include mq(medium) {
				display: block;
				left: 50px;
				bottom: 50px;
				width: 63px;
				height: 63px;
			}
		}
	}
}
.throw-shapes {
	.shape {	
		&-1 {
			right: 0;
			top: 0px;
			width: 20vw;
			transform: translate(100%, -30px) rotate(180deg);
			z-index: 0;
			display: none;
			@include mq(large) {
				display: block;
				transform: translate(100%, 10vw) rotate(180deg);
			}
			@include mq(xlarge) {
				transform: translate(150%, 10vw) rotate(180deg);
			}
			@media all and (-ms-high-contrast:none)
			{
				display: none;
			}
		}
		&-2 {
			left: 0;
			width: 70px;
			height: 70px;
			top: 25%;
			display: none;
			@include mq(large) {
				display: block;
				width: 30vw;
				height: 30vw;
				transform: translateX(calc(-100% - 50px));
			}
			@include mq(xlarge) {
				transform: translateX(calc(-100% - 100px));
			}
			@media all and (-ms-high-contrast:none)
			{
				display: none;
			}
		}
	}
}

.highlight {
	.shape {
		&-1 {
			position: absolute;
			z-index: 0;
			top: 50%;
			left: 0;
			transform: translate(-50%, 20%);
		}
	}
}

.has-shape {
	position: relative;
	overflow: hidden;
}
.text-shape-1 {
	display: none;
	position: absolute;
	z-index: 0;
	width: 60%;
	height: 100%;
	bottom: 0;
	right: 0;
	@include mq(medium) {
		display: block;
		width: 55%;
	}
	@include mq(large) {
		width: 53%;
	}
	// transform: translateX(50%);
}
.content-shape-1 {
	right: 100%;
    top: 0;
    transform: translateX(-100px);
}