.highlight {
	position: relative;
	padding: $spacing-small 0;
	overflow: hidden;
	@include mq(medium) {
		padding: $spacing 0;
	}

	&-list {
		//padding: $spacing-small;
		margin: 0.5rem;
		list-style: none;
		padding: 0;

		@include mq(medium) {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: -$spacing-xl;
		}
		li {
			counter-increment: inst;
			padding: 0.5rem;

			@include mq(medium) {
				width: 50%;
				padding: $spacing-xl;
			}

			@include mq(large) {
				width: 33.333%;
			}

			p:last-child {
				margin-bottom: 0;
			}
		}
		&--item {
			width: 100%;
			height: 100%;
			display: flex;
			text-align: center;
			padding: 3rem 3rem;
			z-index: 1;
			position: relative;

			&:before {
				content: counter(inst);
				font-family: $font-family-display;
				background: $medium-blue;
				color: #fff;
				border-radius: 50px;
				font-size: 1.5rem;
				left: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				transform: translate(-50%, 0);
				height: 50px;
				width: 50px;
				position: absolute;
			}
			p {
				// font-size: 80%;
				margin-top: 5rem;
			}
		}
	}
}