.posts-featured {
	position: relative;
	padding: $spacing-small 0 $spacing-small;
	@include mq(medium) {
		padding: $spacing 0;
	}

	&--title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: $spacing-small / 2;
		@include mq(medium) {
			margin-bottom: $spacing-small;
		}

		h3 {
			margin-bottom: 0;
		}
	}
}