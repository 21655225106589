.title {
	color: $color-text-base;
	padding: $spacing-small 0;
	@include mq(medium) {
		padding: $spacing-small 0 $spacing-small;
	}
	h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
		color: $color-text-base;
	}
	.post-category p {
		margin-bottom: 0;
	}
}