.cta {
	position: relative;
	padding: $spacing-small 0;
	overflow: hidden;
	@include mq(medium) {
		padding: $spacing 0;
		z-index: 1;
	}
	// &::after {
	// 	content: '';
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	right: 1px;
	// 	transform: translateX(100%);
	// 	top: 0;
	// 	background: $carveti-blue;
	// 	z-index: -2;
	// }

	&-content {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		//transform: translate(1.3rem, 0);
		@include mq(medium) {
			transform: translate(0);
			width: 60%;
		}
		@include mq(large) {
			width: 50%;
		}

		h1, h2, h3, h4, h5, h6, p {
			color: white;
		}
	}

	&-container {
		background-size: cover;
		background-position: center center;
		.container {
			@include mq($until: xlarge) {
				overflow: hidden;
			}
		}
	}
}