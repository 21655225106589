// =============================================================================
// Generic
// =============================================================================

html, body {
	height: 100%;
}

body {
	background-color: $body-background-color;
	color: $color-text-base;
	font-family: $font-family-body;
	font-size: fluid-type($font-size-base);
	line-height: $body-line-height;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
	@include mq(xlarge) {
		overflow-x: hidden;
	}
}

img {
	max-width: 100%;
	vertical-align: middle;
	flex-shrink: 0;
}

.gm-style img,
img[width],
img[height] {
	max-width: none;
}

::selection {
	background: $green; /* WebKit/Blink Browsers */
	// color: white;
  }
::-moz-selection {
	background: $green;  /* Gecko Browsers */
}

