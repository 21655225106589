// =============================================================================
// Content
// =============================================================================
article.content {
	padding: $spacing-small 0;
	@include mq(medium) {
		padding: $spacing-small * 1.5 0;
		//padding: $spacing 0;
	}
}

.content.text-details-content {
	padding: 0;
}

.content {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 0 1.3rem;
	// padding-bottom: $spacing-small;
	@include mq(medium) {
		width: 80%;
		// padding-bottom: $spacing-small * 1.5;
	}
	@include mq(large) {
		//width: 75%;
		width: 768px;
	}
	@include mq(xlarge) {
		//width: 50%;
	}

	h1, h2 {
		margin-bottom: 2rem;
	}
	h3, h4, h5, h6 {
		margin-bottom: 0.5em;
	}

	a {
		color: $carveti-blue;
		&:hover {
			color: $dark-blue;
		}
	}

	
	abbr {
		border-bottom: 1px dotted;
		cursor: help;
	}

	cite {
		font-style: italic;
	}

	hr {
		background: $color-grey-light;
		border: none;
		display: block;
		height: 1px;
		margin-bottom: 1.5em;
		margin-top: 1.5em
	}

	img {
		vertical-align:text-bottom
	}

	small {
		font-size: $font-size-small;
	}

	strong {
		font-weight: $font-weight-bold;
	}

	sub, sup {
		font-size: $font-size-small;
	}

	sub {
		vertical-align: sub;
	}

	sup {
		vertical-align: super;
	}

	p:last-child, dl:last-child, ol:last-child, ul:last-child, blockquote:last-child, pre:last-child, table:last-child {
		margin-bottom: 0;
	}

	p:empty {
		display:none
	}

	&-social {
		margin-right: 1rem;
		width: 20px;
		height: 20px;
		transform: translateY(-4px);
	}
}

// Team bio

.bio-title {

	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 0 1.3rem;
	// padding-bottom: $spacing-small;
	@include mq(medium) {
		width: 80%;
		// padding-bottom: $spacing-small * 1.5;
	}
	@include mq(large) {
		//width: 75%;
		width: 768px;
	}
	@include mq(xlarge) {
		//width: 50%;
	}

	h2 {
		margin: 0;
	}

}

.content__bio {
	padding: 0 1.3rem;

	@include mq(medium) {
		display: flex;
		flex-wrap: wrap;
	}

}

.content__bio__info {
	margin: 0 0 2rem;

		@include mq(medium) {
			width: 100%;
		}
	
		h1 {
			margin: 0 0 1rem;
		}

		h2 {
			margin: 0;
		}

}

.content__bio__img {
	width: 20rem;
	margin: 0 auto 2rem;

		@include mq(medium) {
			width: 15rem;
			margin: 0 2rem 0 0;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}

}

.content__bio__text {
	
	@include mq(medium) {
		flex: 1;
	}

}


