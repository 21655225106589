// =============================================================================
// Variables
// =============================================================================
$debug:	false;

/* Colours */
$color-black: #000;
$color-grey-dark: #6a6a6a;
$color-grey: #ddd;
$color-grey-light: #efefef;
$color-white: #fff;

$color-primary: #414BAF;
$color-secondary: #250E62;
$color-tertiary: #6CEADA;
$color-quaternary: #FFC845;

$carveti-blue: $color-primary;
$medium-blue: $color-secondary;
$dark-blue: #1E1A34;
$light-blue: #EDEFF8;
$light-grey: #F7F7F8;
$green: $color-tertiary;
$yellow: $color-quaternary;

$color-text-base: $dark-blue;
$color-text-dark: #181822;
$color-text-reversed: #fff;
$color-text-link: $dark-blue;
$color-text-hover: $carveti-blue;
$color-button: $color-text-link;
$color-button-alt: #B53F97;

$color-facebook: #3B5998;
$color-twitter: #1DA1F2;
$color-instagram: #262626;
$color-youtube: #cc181e;
$color-linkedin: #0077B5;

// Block colours
$block-colors: 
    ( blue, $color-primary ),
	( green, $color-secondary ),
	( orange, $color-tertiary ),
	( red, $color-quaternary );

/* Typography */
$font-family-body: 'effra', BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-display: 'effra', BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-monospace: Menlo, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Consolas, monospace;

$body-background-color: $color-white;
$body-size: 18px;
$body-size-multiplier: 0.3vw;
$body-family: $font-family-body;
$body-color: $color-text-base;
$body-line-height: 1.4;

$heading-line-height: 1.1;
$heading-size-multiplier: 1.5vw;

$font-size-small: .2rem;
$font-size-base: 1rem;
$font-size-medium: 1rem;
$font-size-lead: 1.5rem;

$font-size-h1: 3rem;
$font-size-h2: 2rem;
$font-size-h3: 1rem;
$font-size-h4: 0.35rem;
$font-size-h5: 1rem;
$font-size-h6: $font-size-small;

$button-size: 1.3rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-heavy: 900;
$font-weight-headings: $font-weight-light;

// Spacing

$spacing: 120px;
$spacing-med: 80px;
$spacing-small: 60px;
$spacing-xl: 30px;

// Layout
$content-width: 768px;
$container-small: 768px;
$container: 1600px;

$radius: 4px;
$radius-large: 32px;

$z-scale: (
	foreground: 1,
	overlay: 10,
	navigation: 100,
	modal: 200
);

// Breakpoints
$mq-breakpoints: (
	xsmall: 21em,
    small: 30em,
    small-major: 40em,
    medium: 50em,
    medium-major: 62.5em,
    large: 62.5em,
    xlarge: 80em
);

// $mq-show-breakpoints: (xsmall, small, small-major, medium, medium-major, large, xlarge);
