.hero {
	position: relative;
	z-index: 0;
	background-size: cover;
	background-position: center center;
	
	&-image {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		// height: 70%;
		// width: 50%;
		// right: 0;
		// top: 0;
		overflow: hidden;
		z-index: 0;

			@include mq(large) {
				left: auto;
				right: 0;

				width: 50%;
				height: 100%;
			}


		&--clip {
			position: absolute;
			bottom: -12px;
			min-width: 100%;
			fill: $carveti-blue;
			z-index: 1;
		}

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			// background-color: rgba(255, 255, 255, 0.1);
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		img {
			//position: absolute;
			//left: 0;
			// max-width: none;
			//min-width: 100%;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;

			@include mq(large) {
				// object-fit: none;
				min-width: 100%;
				min-height: 100%;
			}
		}
	}
	&-content {
		width: 100%;
		position: relative;
		z-index: 1;
		@include mq(large) {
			width: 50%;
		}

		h1, h2, h3, h4, h5, h6, p {
			// color: white;
			margin-top: 0;
		}
		h1, h2, h3, h4 + .btn {
			margin-bottom: 1.5rem;
		}

		// .btn {
		// 	background-color: white;
		// }

		&--supporting {
			width: 100%;
			@include mq(medium) {
				width: 85%;
			}
		}
		&--dropdown {
			text-align: left;

			p {
				margin-bottom: 1rem;
			}

			select {
				width: 100%;
			}
		}
	}

	&-home {
		padding: $spacing-small 0;
		@include mq(medium) {
			padding: $spacing 0 $spacing;
		}


		h1, h2, h3, h4, h5, h6, p {
			color: white;
			margin-top: 0;
		}
		.hero-image {
			// border-bottom-right-radius: 200% 80%;
			border-bottom-right-radius: 100%;
			@include mq(medium) {
				height: calc(100% - 80px);
			}
			img {
				opacity: 0.3;
			}
			&--clip {
				right: 0;
				min-width: 100%;
				fill: $carveti-blue;
				// display: none;
			}
		}
	}

	&-page {
		padding: $spacing * 1.5 0 $spacing-small;
		@include mq(medium) {
			padding: $spacing * 1.5 0;
		}

		.hero-image {
			// border-bottom-right-radius: 50% 50%;
			@include mq(medium) {
				height: 100%;
			}
			img {
				opacity: 0.3;
				@include mq(large) {
					opacity: 1;
				}
			}
			&--clip {
				right: 0;
				fill: $carveti-blue;
				// display: none;
				width: 50%;
				min-width: 50%;
				@include mq(medium) {
					// left: 10%;
				}
			}
		}
	}
	&-portfolio {
		padding: $spacing-small 0;
		text-align: left;
		overflow: hidden;
		@include mq(medium) {
			text-align: center;
			padding: $spacing 0;
		}
		.hero-content {
			width: 100%;
			margin: 0 auto;
			@include mq(medium) {
				width: 60%;
			}
			@include mq(large) {
				width: 50%;
			}
			&--supporting {
				width: 100%;
				margin: 0 0 $spacing-small / 2;
			}
		}
	}
	&-blog {
		padding: $spacing-small 0;
		@include mq(medium) {
			padding: $spacing * 1.5 0;
		}

		.hero-image {
			border-bottom-right-radius: 80% 50%;
			@include mq($until: medium) {
				display: none;
			}
			@include mq(medium) {
				height: 100%;
			}
			img {
				opacity: 0.3;
				@include mq(large) {
					opacity: 1;
				}
			}
			&--clip {
				left: 0;
				fill: $light-blue;
				@include mq(medium) {
					left: 10%;
				}
			}
		}
		.hero-content {
			width: 100%;
			@include mq(medium) {
				width: 50%;
			}
			padding-right: 2rem;
			h1, h2, h3, h4, p {
				color: $color-text-base;
			}
		}
	}
	&-video {
		height: 50vh;
		position: relative;
		display: flex;
		flex: 1 0 auto;
		padding: $spacing-small 0 $spacing-small;
		@include mq(medium) {
			height: 75vh;
			padding: $spacing 0 $spacing * 1.5;
		}
		h1, h2, h3, h4, h5, h6, p {
			color: white;
			margin-top: 0;
		}
		// &:before {
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	background: $carveti-blue;
		// 	height: 100%;
		// 	width: 100%;
		// 	opacity: 0.9;
		// 	// border-bottom-right-radius: 30% 60%;
		// }
		&--video {
			height: 100%;
			overflow: hidden;
			position: absolute;
			left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
			width: 100%;
			z-index: -1;

			video {
				object-fit: cover;
				width: 100vw;
				height: 100vh;
				position: absolute;
				top: 0;
				left: 0;
			}
		} 
	}
	input[type="email"],
	input[type="number"],
	input[type="search"],
	input[type="text"],
	input[type="tel"],
	input[type="url"],
	input[type="password"],
	textarea,
	select {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
		border-radius: 10px;
		&:hover {
			box-shadow: 0 5px 10px rgba(0,0,0,.2);
			transition: box-shadow .2s ease-in-out;
		}
	}
}

.hero-shape {
	display: none;
	width: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(0px, -50%);
	@include mq(large) {
		width: 60%;
		max-width: 650px;
		display: block;
	}
	@include mq(xlarge) {
		display: block;
		width: 40%;
	}
}