.post {
	&-category {
		position: relative;
		display: flex;

		p {
			padding: 0.3rem;
			margin-bottom: 1rem;
			font-family: $font-family-display;
			font-size: 14px;
			background-color: $light-blue;
		}
	}

}
.posts-filter {
	display: flex;
	justify-content: center;
	margin-bottom: $spacing-small;
	.btn {
		background: $medium-blue;
		color: white;
		margin: 0 1rem;
		&:hover {
			background: $yellow;
			color: $color-text-base;
		}
	}
	.selected {
		background: $green;
		color: $color-text-base;
		&:hover {
			background: $yellow;
			color: $color-text-base;
		}
	}
	+ form, + select {
		margin-bottom: 3rem !important;

		input[type="email"],
		input[type="number"],
		input[type="search"],
		input[type="text"],
		input[type="tel"],
		input[type="url"],
		input[type="password"],
		textarea,
		select {
			border: 1px solid $medium-blue;
		}
	}
}

.post-category {
	margin: 0.25rem;
}