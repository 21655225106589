// =============================================================================
// Typography
// =============================================================================

p {
	font-size: fluid-type($font-size-base);
	
	@include mq($until: medium) {
		font-size: $body-size;
	}

}

p + p {
	margin: 1.4em 0 0;
}

p + .btn {
	margin: 3rem 0 0;
}

p.lead {
	font-size: fluid-type($font-size-lead);
	@include mq($until: medium) {
		font-size: 1.25rem;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $font-family-display;
	color: $color-text-base;
	text-rendering: optimizelegibility;
	margin-bottom: .5em;
	margin-top: 0;
	font-weight: $font-weight-headings;
	line-height: $heading-line-height;
}

* + h1, * + h2, * + h3, * + h4, * + h5, * + h6, * + .h1, * + .h2, * + .h3, * + .h4, * + .h5, * + .h6 {
	margin-top: 1.4em;
}

h1, .h1 { font-size: fluid-type-heading($font-size-h1); }
h2, .h2 { font-size: fluid-type-heading($font-size-h2); }
h3, .h3 { font-size: fluid-type-heading($font-size-h3); }
h4, .h4 { font-size: fluid-type-heading($font-size-h4); }
h5, .h5 { font-size: fluid-type-heading($font-size-h5); }
h6, .h6 { font-size: fluid-type-heading($font-size-h6); }

h1, .h1 {
	@include mq($until: medium) {
		font-size: 2.8rem;
	}
}
h2, .h2 {
	@include mq($until: medium) {
		font-size: 2.4rem;
	}
}
h3, .h3 {
	@include mq($until: medium) {
		font-size: 1.9rem;
	}
}
h4, .h4 {
	@include mq($until: medium) {
		font-size: 1.75rem;
	}
}


h6, .h6 {
	@include mq($until: medium) {
		font-size: 1.5rem;
	}
}

.text-white {
	color: white;

	h1, h2, h3, h4, h5, p {
		color: white;
	}
}

a {
	color: $color-text-link;
	text-decoration: underline;
}

a:focus,
a:hover {
	color: $color-text-hover;
	text-decoration: underline;
}

blockquote {
	background: $light-blue;
	margin-left: 0;
	padding: 1.3rem 2rem;
	text-align: center;
	font-family: $font-family-display;
	font-size: fluid-type($font-size-lead);
	color: $medium-blue;
	border-radius: 10px;
	@include mq(medium) {
		padding: 1.3rem 6.5rem;
	}
}

blockquote p {
	margin-bottom: 0.2em;
	font-size: fluid-type($font-size-lead);
}

blockquote footer {
	color: $color-text-dark;
	font-size: $font-size-small;
}

ol, ul {
	padding-left: 1.5em;
}
ul {
	list-style-type: disc;
	padding-left: 1.5rem;
	li {
		position: relative;
		margin: 0.5rem 0;
		@include mq($until: medium) {
			font-size: $body-size;
		}
	}
}
ul.tick {
	list-style-type: none;
	padding-left: 2rem;
	@include mq(medium) {
		padding-left: 3.5rem;
	}
	li {
		position: relative;
		margin: 1.3rem 0;
		font-family: $font-family-display;
		&::before {
			content: url('../src/icons/list-icon.svg');
			position: absolute;
			left: -3.5rem;
			width: 40px;
			height: 40px;
		}
		@include mq($until: medium) {
			font-size: $body-size;
			&::before {
				content: url('../src/icons/list-icon--small.svg');
				left: -2rem;
				top: 3px;
				width: 20px;
				height: 20px;
			}
		}
	}
}

dt {
	margin-top: 1em;
	font-weight: bold;
}

dd {
	margin-left: 0;
}
