// =============================================================================
// Site
// =============================================================================

.global-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.container {
	margin: 0 auto;
	padding: 0 1.3rem;
	max-width: $container;
	width: 100%;
}
.container--small {
	margin: 0 auto;
	padding: 0 1.3rem;
	max-width: $container - 150px;
	width: 100%;
}

.container--half {
	display: flex;
	flex-direction: column;
	width: 100%;
	@include mq(medium) {
		flex-direction: row;
	}
	> div {
		width: 100%;
		margin-bottom: 1.6rem;
		@include mq(medium) {
			width: 50%;
			margin-bottom: 0;
		}
	}
	+ .container--half {
		margin-top: 1.3rem;
		@include mq(medium) {
			margin-top: $spacing-small;
		}
	}
}

.bkg-light-blue,
.bkg-light-blue + .cta-container {
	background-color: $light-blue;
	blockquote {
		background: white;
	}

	.post-category p {
		background-color: white;
	}
}
.bkg-blue,
.bkg-blue + .cta-container {
	background-color: $carveti-blue;
}
.bkg-medium-blue,
.bkg-medium-blue + .cta-container {
	background-color: $medium-blue;
}
.bkg-dark-blue,
.bkg-lidarkght-blue + .cta-container {
	background-color: $dark-blue;
}
.bkg-green,
.bkg-green + .cta-container {
	background-color: $green;

	.section-title {
		h1, h2, h3, h4, h5, h6, p {
			color: $color-secondary;
		}
	}
}
.bkg-yellow,
.bkg-yellow + .cta-container {
	background-color: $yellow;

	h1, h2, h3, h4, h5, h6, p {
		color: $color-secondary;
	}
}

.content + .bkg-white {
	padding-top: $spacing-small;
	@include mq(medium) {
		padding-top: $spacing-small * 1.5;
		margin-top: 0;
	}
}
.content + .bkg-yellow {
	margin-top: 0;
}

.bkg-white,
.bkg-white + .cta-container {
	background-color: white;
	blockquote {
		background: white;
	}
}
.section-title {
	width: 100%;
	padding: 0 1.3rem;
	margin: 0 auto;
	margin-bottom: $spacing-small / 1.5;
	@include mq(medium) {
		text-align: center;
		width: 75%;
		margin-bottom: $spacing / 1.5;
	}
	@include mq(large) {
		width: 50%;
	}

	p:last-child {
		margin-bottom: 0;
	}
}

// New styles

.pg-content {
	// padding: $spacing-small 0 0 0;

	// @include mq(medium) {
	// 	padding: $spacing-small * 1.5 0 0 0;
	// }

	> * + * {
		margin: $spacing-small auto 0;

			@include mq(medium) {
				margin: $spacing auto 0;
			}

	}
	.container--half {
		margin-top: $spacing-small !important;
	}

}

.inner-spaced {
	padding: $spacing-small 0;

		@include mq(medium) {
			padding: $spacing 0;
		}

}

.bkg-medium-blue + .cta-container {
		margin: -$spacing-small 0 0;
		padding: 0 0 $spacing-small;

			@include mq(medium) {
				margin: -$spacing 0 0;
				padding: 0 0 $spacing;
			}

}