// =============================================================================
// Footer
// =============================================================================
// .cta-container + .global-footer {
// 	@include mq(medium) {
// 		padding: $spacing * 2 1.3rem $spacing 1.3rem;
// 		transform: translateY(-30%);
// 	}
// }
.global-footer {
	margin-top: auto;
	padding: $spacing-small 1.3rem;
	text-align: center;
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	align-items: center;
	background: $dark-blue;
	z-index: 0;
	@include mq(medium) {
		padding: $spacing 1.3rem $spacing 1.3rem;
	}
	&--logo {
		width: 130px;
		@include mq(medium) {
			width: 160px;
		}
	}
	&--nav {
		ul {
			margin-top: 55px;
			padding-left: 0;
			li {
				a {
					font-size: 1.15rem;
					color: white;
					margin: 0 1rem;
					text-decoration: none;
					&:focus,
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
}