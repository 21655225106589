// =============================================================================
// Gutenberg styling
// =============================================================================

/* Post title width */
.editor-post-title__block.wp-block {
	max-width: $content-width;

	/* Post title styling */
	.editor-post-title__input {
	}
}

/* Main column width */
.wp-block {
    max-width: $content-width;

	/* Wide column width */
	&[data-align="wide"],
	&[data-align="full"] {
		max-width: none;
	}
}

// Undo blockquote styles
.wp-block-quote:not(.is-large):not(.is-style-large) {
	border-left: 0;
	padding-left: 0;
}

// Fix space between image and caption
.wp-block-image {
	line-height: 0;
}

// Full width button
.wp-block-button.is-style-full,
.wp-block-button.is-style-full .block-editor-rich-text,
div[data-type="core/button"] div[data-block] {
	display: block;
}

// Separator
.wp-block-separator:not(.is-style-wide):not(.is-style-dots) {
	max-width: 100%;
}

