/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding:0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;
    // max-height: 600px;

    display: block;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: center;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;
    position: relative;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: inline-block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

$slick-font-path: "../images/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

// .slick-list {
//     .slick-loading & {
//         background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
//     }
// }

/* Icons */

/* Arrows */
.projects-slider--images {
    .slick-next {
        position: absolute;
        display: none !important;
        height: calc(100% + 2px);
        // width: calc(20% + 1rem);
        width: 20%;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.9);
        color: transparent;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        margin-top: -1px;
        padding: 0;
        border: none;
        outline: none;
        z-index: 1;
        right: -4px;
        border-radius: 0px;
        @include mq(large) {
            display: block !important;
            
        }
        &:hover {
            background: rgba(255, 255, 255, 0.8);
        }
        &:hover, &:focus {
            outline: none;
            color: transparent;
        }
        &.slick-disabled:before {
            opacity: $slick-opacity-not-active;
        }
        &:before {
            font-family: $slick-font-family;
            font-size: 20px;
            line-height: 1;
            color: $slick-arrow-color;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    .slick-prev {    
        display: none !important;
    }
    .slick-dots {
        position: absolute;
        bottom: -35px;
    }
}
.team-list {
    .slick-track {
        align-items: baseline;
    }

    .slick-prev {    
        left: 1rem;
        transform: translateY(-50%) rotate(180deg);
        &:before {
            content: url(../src/icons/carousel-prev--small.svg);
            [dir="rtl"] & {
                content: url(../src/icons/carousel-prev--small.svg);
            }
        }
    }
    
    .slick-next {
        right: 1rem;
        &:before {
            content: url(../src/icons/carousel-next--small.svg);
            [dir="rtl"] & {
                content: url(../src/icons/carousel-prev--small.svg);
            }
        }
    }
}   

.highlight-list {
    z-index: 1;
    // display: flex;
    .slick-prev {
        &:before {
            content: url(../src/icons/carousel-prev.svg);
            [dir="rtl"] & {
                content: url(../src/icons/carousel-next.svg);
            }
        }
    }
}

.image {
    .slick-dots {
        text-align: left;
        max-width: $container;
        margin: -60px auto 20px auto;
        padding: 0 1.3rem;
    }
}

.slick-prev,
.slick-next {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: $green;
    border-radius: 100px;
    color: transparent;
    top: 50vw;
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;
    &:hover, &:focus {
        outline: none;
        background: $yellow;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-family: $slick-font-family;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-prev {    
    left: 1rem;
    &:before {
        content: url(../src/icons/carousel-prev.svg);
        [dir="rtl"] & {
            content: url(../src/icons/carousel-next.svg);
        }
    }
}

.slick-next {
    right: 1rem;
    &:before {
        content: url(../src/icons/carousel-next.svg);
        [dir="rtl"] & {
            content: url(../src/icons/carousel-prev.svg);
        }
    }
}

.slick-dots {
    position: relative;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 15px;
            width: 15px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            border-radius: 10px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                background: $carveti-blue;
                &:before {
                    border: 2px solid $carveti-blue;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 15px;
                height: 15px;
                text-align: center;
                background: transparent;
                border: 2px solid $green;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                opacity: 1;
                border-radius: 10px;
            }

        }
        &.slick-active button {
            &:hover, &:focus {
                outline: none;
                background: $carveti-blue;
                &:before {
                    border: 2px solid $green;
                }
            }
            &:before {
                background: $green;
                opacity: 1;
                height: 15px;
                width: 15px;
            }
            &:hover, &:focus {
                border: 2px solid $green;
            }
            
        }
    }
}
