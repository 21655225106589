.post-list {
	position: relative;
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	flex-direction: column;
	@include mq(small) {
		flex-direction: row;
		margin: 0 -1.3rem;
	}
	+ .btn {
		margin-top: 1rem;
	}

	&--item {
		display: flex;
		width: 100%;
		margin: 0 0 3rem 0;
		flex-direction: column;

		@include mq(small) {
			width: calc(100% / 2);
			padding: 0 1.3rem;
			margin: 0 0 3rem 0;
		}
		@include mq(medium) {
			width: calc(100% / 3);
		}

		.post-category { 
			margin: 1.5rem 0;
			@include mq(medium) {
				margin: 2rem 0 1rem 0;
			}
			p {
				margin: 0;
			}
		}
	}
}

// New styles

.post-list--item a {
	display: block;
	font-size: fluid-type($font-size-lead);
}

.post-list--item a img {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
	object-position: center;
}